import React from 'react'
import moment from 'moment';

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;


export default function SearchViewContent({ slug, data }) {

    //console.log('data',data);
    return (
        <div className="container mt-5">

            {data.news && data.news.length > 0 && <div class="row">
                <div class="col-lg-6 ">
                    <div class="section-title  pb-15">
                        <h5>Actualites</h5>
                    </div>
                </div>
            </div>}
                {data.news && data.news.map(news => (
                    <div className="row mt-4 border-bottom p-4 bg-gray" key={news.id}>
                        <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                            <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                                <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(news.created_at).format('DD.MM.YYYY')}</small>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-9 pr-3 pb-0 bd-highlight">
                            <div className="header adapt">
                                <h5 className=" semibold adapt bottom-5"><a className="doc-title text-dark">{news.title}</a></h5>

                            </div>
                        </div>
                        <div className="col-sm-12 col-md-1 pb-0 bd-highlight`">
                            <div className="action-btn">
                                <a href={`/news/${news.permalink}`} type="button"
                                    className="pb-1 pl-0 btn btn-link text-uppercase d-inline-flex font-weight-bold">
                                    Lire
                                </a>

                            </div>
                        </div>
                    </div>
                ))
                }

            {data.page && data.page.length > 0 && <div class="row">
                <div class="col-lg-6">
                    <div class="section-title pb-15">
                        <h5>Pages</h5>
                    </div>
                </div>
            </div>}

            {data.page && data.page.map(page => (
                <div className="row mt-4 border-bottom p-4 bg-gray" key={page.id}>
                    <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                        <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                            <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(page.created_at).format('DD.MM.YYYY')}</small>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-9 pr-3 pb-0 bd-highlight">
                        <div className="header adapt">
                            <h5 className=" semibold adapt bottom-5"><a className="doc-title text-dark">{page && page.title}</a></h5>

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-1 pb-0 bd-highlight`">
                        <div className="action-btn">
                            <a href={`/page/${page.permalink}`} type="button"
                                className="pb-1 pl-0 btn btn-link text-uppercase d-inline-flex font-weight-bold">
                                Lire
                            </a>

                        </div>
                    </div>
                </div>
            ))
            }





            {data.partner && data.partner.length > 0 && <div class="row">
                <div class="col-lg-6">
                    <div class="section-title pb-15">
                        <h5>Partenaires</h5>
                    </div>
                </div>
            </div>}


            {data.partner && data.partner.map(partner => (
                <div className="row mt-4 border-bottom p-4" key={partner.id}>
                    <div className="col-sm-12 col-md-2 p-0 bd-highlight  ">
                        <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                            <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(partner.created_at).format('DD.MM.YYYY')}</small>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-7 pr-3 pb-0 bd-highlight">
                        <div className="header adapt">
                            <h5 className="adapt bottom-5"><a className="doc-title text-dark">{partner && partner.name}</a></h5>
                            <span className="black adapt bottom-15 adapt light text-justify doc-desc "
                                data-keywords="&quot;&quot;"></span>
                            <br />
                        </div>
                    </div>
                    {/* <div className="col-sm-12 col-md-3 pb-0 bd-highlight`">
                        <div className="action-btn">
                            <a href={`${partner.website_link}`} type="button"
                                className="pb-1 pl-0 btn btn-link text-uppercase d-inline-flex font-weight-bold">
                                Lire
                            </a>

                        </div>
                    </div> */}
                </div>
            ))
            }





            {data.documentation && data.documentation.length > 0 && <div class="row">
                <div class="col-lg-6">
                    <div class="section-title pb-15">
                        <h5>Documentations</h5>
                    </div>
                </div>
            </div>}

            {data.documentation && data.documentation.map(documentation => (
                <div className="row mt-4 border-bottom p-4" key={documentation.id}>
                    <div className="col-sm-12 col-md-2 p-0   ">
                        <div className="doc-infos light adapt nowrap left-20-in right-20-in ">
                            <small className="d-block semibold bottom-15 adapt pb-2">Publié le <i className="fa fa-calendar"></i> {moment(documentation.created_at).format('DD.MM.YYYY')}</small>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-7 pr-3 pb-0 ">
                        <div className="header adapt">
                            <h5 className="adapt bottom-5"><a className="doc-title text-dark">{documentation && documentation.title}</a></h5>
                            <span className="black adapt bottom-15 adapt light text-justify doc-desc "
                                data-keywords="&quot;&quot;"></span>
                            <br />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3 pb-0 `">
                        <div className="action-btn">

                            <a href={`${directory_doc}/${documentation.fichier.filename}`} className="pb-1 pr-0 btn btn-link text-uppercase font-weight-bold download">
                                Télécharger
                            </a>
                        </div>
                    </div>
                </div>
            ))
            }
        </div>
    )
}
