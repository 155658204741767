import React, { useEffect, useState } from 'react';
import { getResource } from '../../../service/api';
import ContentLoader from 'react-content-loader'
import { Alert } from 'react-bootstrap';
const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

const MyLoader = () => (
    <ContentLoader viewBox="0 0 380 150">
        <rect x="0" y="20" rx="4" ry="4" width="100%" height="18" />
        <rect x="0" y="45" rx="3" ry="3" width="100%" height="18" />
        <rect x="0" y="70" rx="3" ry="3" width="100%" height="18" />
        <rect x="0" y="95" rx="3" ry="3" width="100%" height="18" />
    </ContentLoader>
)

const ContentModuleStatisticDocumentation = ({ tag }) => {
    const [module, setModule] = useState(null)
    const [taille, setTaille] = useState(0)
    const [documents, setDocuments] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getResource(`modules?tag=${tag}`).then(res => {
            setModule(res.data.data.length > 0 ? res.data.data[0] : null)
            
        })
    }, [])

    useEffect(() => {
        setLoading(true)

        if(module)
        getResource(`documentations?module_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setDocuments(res.data.data)
            setTaille(res.data.total)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
            setLoading(false)

        })
    }, [currentPage, module])

    return (

        <>
   
            <div class="list-group p-3 section-title">
                <h5 className="mb-20">Document(s) téléchargeable(s)</h5>
                {!loading && taille > 0 ? documents.map(c => (
                    <a href={`${directory_doc}/${c.fichier.filename}`} key={c.id}  class={`list-group-item list-group-item-action cursor p-3`} aria-current="true">
                        {c.title} <i className="fa fa-download pull-right"></i></a>
                )) : <Alert className="col-md-12 mx-auto" variant="secondary"><h4 className="text-success">Aucun document publié</h4></Alert>}
                {loading && <MyLoader />} 
            </div>
        </>
    );
}

export default ContentModuleStatisticDocumentation;