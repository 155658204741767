import React, { createContext, useEffect, useState } from "react";
import AppRouter from "./AppRouter";
import Loader from "./components/Common/Loader";
import { getResource } from "./service/api";
import Cookies from "js-cookie";
import axios from 'axios';
import ReactGA from 'react-ga'



export const AppContext = createContext()

const App = () => {
  const [appState, setAppState] = useState(INITIAL_APP_STATE)

  useEffect(() => {
    loadDefaultWebsiteDatas()
  }, [])

  const loadDefaultWebsiteDatas = async () => {
    const res = await getResource("defaultdatas")
    setAppState({ ...appState, defaultdatas: res.data })
  }

  React.useEffect(() => {
    ReactGA.initialize('UA-220096328-1')
    ReactGA.pageview(window.location.pathname + window.location.search)

  }, [window.location]);


  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      {appState.defaultdatas && <AppRouter />}

      {!appState.defaultdatas && <Loader />}
    </AppContext.Provider>
  );
}

export default App;

const INITIAL_APP_STATE = { searchBarDisplay: false, collapsed: 'on', defaultdatas: null }