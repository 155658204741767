import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import {getResource} from '../service/api';
import { useParams } from 'react-router';
import ContentModulePhotos from '../components/Module/ContentModulePhotos';

export default function Phototheque() {

    useEffect(() => {

        // getResource(`pages?permalink=${slug}`).then(res => {
        //     if(res.data.data.length>0){
        //         setCurrentpage(res.data.data[0]);
        //     }
            
        // })

    }, []);

    return (
        <>
            <Ariane title="Photothèques"/>
            <ContentModulePhotos />
        </>
    )
}
