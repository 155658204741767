import React, { useContext } from 'react';
import "../../styles/slider.css";
import Slider from "react-slick";
import { AppContext } from '../../App';



const directory = process.env.REACT_APP_UPLOAD_DIRECTORY


export default function BannerSlider() {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div className="" >

            <span
                {...props}
                className={
                    "prev slick-arrow" +
                    (currentSlide === 0 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}
                aria-hidden="true"
                aria-disabled={currentSlide === 0 ? true : false}
            >
                <i class="fa fa-angle-left"></i>
            </span>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

        <div className="" >
            <span
                {...props}
                className={
                    "next slick-arrow" +
                    (currentSlide === slideCount - 1 ? " slick-disabled" : "")
                }
                style={{ display: "block" }}

                aria-hidden="true"
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
            >
                <i class="fa fa-angle-right"></i>

            </span>
        </div>
    )
    const SliderSettings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 600,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        focusOnSelect: true,
        draggable: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <section id="slider-part" >

            <Slider {...SliderSettings} className="slider-active">
                {defaultdatas && defaultdatas.news.data.map(s => (
                    <div>
                        <div className="single-slider bg_cover pt-50" style={{ backgroundImage: `url("${directory + '/' + s.fichier.filename}")` }}    >
                            <div className="container">
                                <div className="row">
                                    <div >
                                        <div className="slider-cont ml-50 pt-25 pb-25 pl-25 pr-25">
                                            <h4 >{s.title}</h4>
                                            <a data-animation="fadeInUp" data-delay="1.6s"
                                                    className="main-btn" href={`/news/${s.permalink}`}>Lire plus</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </section>

    )
}
