import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { getResource, postResource } from '../../../service/api';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ContentModuleStatisticAnimalViewer from './ContentModuleStatisticAnimalViewer';
import ContentModuleStatisticTableGraphe from './ContentModuleStatisticTableGraphe';
import ContentModuleStatisticDocumentation from './ContentModuleStatisticDocumentation';
import Select from 'react-select'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ContentModuleStatisticAnimal_ = () => {
    const [currentThematique, setThematique] = useState(null)
    const [animals, setAnimals] = useState([])
    const [zone, setZone] = useState(null)
    const [zones, setZones] = useState([])
    const [years, setYears] = useState([])
    const [year, setYear] = useState(null)
    const [loadingResult, setLoadingResult] = useState(false)

    const [current_animal, setCurrentAnimal] = useState([])
    const [current_year, setCurrentYear] = useState([])
    const [current_zone, setCurrentZone] = useState(["BENIN"])

    const [datasets, setDataSets] = useState([])
    const [printdata, setPrintData] = useState([])


    const [modal, setModal] = useState(false)
    const [modalFilter, setModalFilter] = useState(false)
    const [currentIndicateur, setCurrentIndicateur] = useState(null)
    const [analyse, setAnalyse] = useState({ caption: "Analyse des données suivant l'Effectif de Cheptel", data: "effectif_cheptel", unit: "" })
    const [view, setView] = useState("line")
    const [commune, setCommune] = useState('BENIN')
    const [communes, setCommunes] = useState([])
    const [doRequest, setDoRequest] = useState(false)

    useEffect(() => {
        loadAnimals();

    }, [])

    useEffect(() => {
        loadYears()
    }, [current_animal])

    

    useEffect(() => {
        setZones(null)
        if (zone == 'communes') loadCommunes()
        if (zone == 'pda') loadPDA()
        if (zone == 'departements') loadDepartement([])

    }, [zone])

    useEffect(() => {
        // setZones(null)
        if (year == 'select_year') loadYears()
    }, [year])

    const toggleModal = (current = null) => {
        setModal(!modal)
        setCurrentIndicateur(current)
    }

    const toggleModalFilter = (thematique) => {
        setModalFilter(!modalFilter)
        if (thematique == "effectif_cheptel") setAnalyse({ caption: "Analyse des données suivant l'effectif de cheptel", data: "effectif_cheptel", unit: "" })
        if (thematique == "qte_viande") setAnalyse({ caption: "Analyse des données suivant la quantité de viande", data: "qte_viande", unit: "T" })
        if (thematique == "nbre_tete_abattu") setAnalyse({ caption: "Analyse des données suivant le nombre de tête abattu", data: "nbre_tete_abattu", unit: "" })
        if (thematique == "poids_carcasse") setAnalyse({ caption: "Analyse des données suivant le poids carcasse", data: "poids_carcasse", unit: "" })
        if (thematique == "qte_lait") setAnalyse({ caption: "Analyse des données suivant la quantité de lait", data: "qte_lait", unit: "T" })
        if (thematique == "nbre_ruche") setAnalyse({ caption: "Analyse des données suivant le nombre de ruche", data: "nbre_ruche", unit: "" })
        if (thematique == "qte_miel") setAnalyse({ caption: "Analyse des données suivant la quantité de miel", data: "qte_miel", unit: "T" })
        setThematique(thematique)
    }


    const loadAnimals = () => {
        getResource('animals').then(res => {
            setAnimals(res.data.map(d => ({ value: d.animal, label: d.animal })))
        })
    }

    const loadCommunes = () => {
        getResource('communes_all').then(res => {
            setZones(res.data.map(d => ({ value: d.name, label: d.name })))
        })
    }

    const loadPDA = () => {
        getResource('pdas').then(res => {
            setZones(res.data.map(d => ({ value: d.pda, label: d.pda })))
        })
    }

    const loadDepartement = () => {
        getResource('departements_all').then(res => {
            setZones(res.data.map(d => ({ value: d.departement, label: d.departement })))
        })
    }

    const loadYears = () => {
        postResource('years_animal',{animals:current_animal}).then(res => {
            setYears(res.data.map(d => ({ value: d.year, label: d.year })))
            setCurrentYear(res.data)
        })
    }

    // const findResult = () => {
    //     setLoadingResult(true)
    //     postResource('animalDatas', {
    //         animals: current_animal,
    //         years: current_year.map(c=>c.year),
    //         communes: zones ? current_zone : ['BENIN']
    //     }).then(res => {

    //         setDataSets(res.data)
    //         toggleModal()
    //         setLoadingResult(false)
    //     }).finally(()=>{
    //         setLoadingResult(false)
    //     })

    // }


    const findResult = () => {
        setLoadingResult(true)

        let query = {
            animals: current_animal,
            years: current_year.map(c => c.year),
            communes: zones ? current_zone : ['BENIN']
        }
        if (zone == "departements") {
            query = {
                animals: current_animal,
                years: current_year.map(c => c.year),
                departement: current_zone[0]
            }
        }

        postResource('animalDatas', query).then(res => {
            if(res.data.length==0){
                alert('Aucun résultat disponible pour cette sélection, Veuillez effecuer un autre choix')
            }else {
                setDataSets(res.data)
                toggleModal()
                setLoadingResult(false)
                let data_print = Object.keys(datasets).map(cultures => datasets[cultures].map(c => c))[0]
                setPrintData(data_print ? data_print : [])
            }


        }).finally(() => {
            setLoadingResult(false)
        })

    }



    return (
        <div>
            <div className="">
                <div className="">
                    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#indicateursclés" role="tab"
                                aria-controls="indicateursclés" aria-selected="true">Indicateurs Clés
            </a>
                        </li>

                        {/* <li>
                            <div className="input-group ml-5 w-75">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-transparent border-right-0" id="basic-addon1"><i className="fa fa-search"></i></span>
                                </div>
                                <input type="text" className="form-control border-left-0" placeholder="Filtre..." aria-label="Username"
                                    aria-describedby="basic-addon1" />
                            </div>
                        </li> */}
                    </ul>
                    <div className="tab-content p-3  " >
                        <div className="tab-pane fade show active pt-2" id="indicateursclés" role="tabpanel"
                            aria-labelledby="indicateursclés">
                            <div className="tab-content" id="nav-tabContent">

                                <div className="tab-pane fade show active" id="toutes-les-thématiques" role="tabpanel"
                                    aria-labelledby="toutes-les-thématiques">
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15" onClick={() => toggleModalFilter('effectif_cheptel')}>Effectif cheptel</button>
                                    <button className="btn btn-sm btn-warning pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('qte_viande')}>Qté viande (T)</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_tete_abattu')}>Nbre de têtes abattues contrôlées</button>
                                                                        <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('poids_carcasse')}>Poids carcasse abattage contrôlé (T)</button>
                                                                            <button className="btn btn-sm btn-success pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('qte_lait')}>Qté de lait (T)</button>
                                          <button className="btn btn-sm btn-danger pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_ruche')}>Nbre de ruches</button>
                                                                                  <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('qte_miel')}>Qté de miel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>


                <div id="mySidenav" className={`sidenav section-title ${modalFilter && 'active'} text-white`}>
                    <i className="closebtn fa fa-close" onClick={() => toggleModalFilter()}></i>
                    <h5 className="text-white mb-4 text-uppercase">INDICAteUR {currentThematique}</h5>
                    <div>
                        <h6 className="text-white mt-5 mb-3  criteria">Selectionner animal</h6>
                        <div >
                            {animals && <div className="zone_area mb-3 notopradius nobottomleft nobottomright">
                                <Select isMulti options={animals} onChange={(animal) => setCurrentAnimal(animal.map(c => c.value))} />
                            </div>}
                        </div>
                        <h6 className="text-white mt-5  criteria mb-3  ">Zones géographiques</h6>
                        <div >
                            <div className="btn-group special " role="group" aria-label="Zones géographiques">
                                <button type="button" className={`btn ${!zone && 'btn-warning'}`} onClick={() => setZone(null)}>Bénin</button>
                                <button type="button" className={`btn ${zone == 'pda' && 'btn-warning'}`} onClick={() => setZone('pda')}>PDA</button>
                                <button type="button" className={`btn ${zone == 'departements' && 'btn-warning'}`} onClick={() => setZone('departements')}>Départements</button>
                                <button type="button" className={`btn ${zone == 'communes' && 'btn-warning'}`} onClick={() => setZone('communes')}>Communes</button>


                            </div>
                            {zones && <div className="zone_area mb-3 notopradius nobottomleft nobottomright">
                                <Select  options={zones} onChange={(zone) => setCurrentZone([zone.value])} />
                            </div>}
                        </div>

                        <h6 className="text-white mt-5  criteria mb-3  ">Années</h6>
                        <div className="btn-group special " role="group" aria-label="Années">
                            <button type="button" className={`btn ${!year && 'btn-warning'}`} onClick={() => setYear(null)}>Toutes</button>
                            <button type="button" className={`btn ${year == 'select_year' && 'btn-warning'}`} onClick={() => setYear('select_year')}>Selectionner</button>
                        </div>
                        {year && <div className="zone_area mb-3 notopradius nobottomleft nobottomright  ">
                            <Select isMulti options={years} onChange={(year) => setCurrentYear(year.map(c => ({year:c.value})))} />
                        </div>}

                    </div>
                    <Button disabled={current_animal.length ==0 } className={`btn btn-lg ${current_animal.length > 0  &&'btn-info'} btn-block text-white mt-40`} onClick={() => { findResult() }}>Voir le résultat <i className={`fa ${loadingResult ? ' fa-spinner fa-pulse' : 'fa-search'} `}></i></Button>

                </div>

                <Modal isOpen={modal} toggle={toggleModal} size="lg" className="custom-modal-style">
                    <ModalHeader toggle={toggleModal}>Visualisation de données   {currentIndicateur && currentIndicateur.animal}</ModalHeader>
                    <ModalBody>

                        <div className="card">
                            <div className="card-body bg-gray">
                                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'line' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("line")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Linéaire
                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'bar' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("bar")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Histogramme
                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className={`nav-link ${view == 'map' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("map")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Carte
                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'table' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("table")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Tableau
                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content tab-content-white" >
                                    {datasets && <ContentModuleStatisticAnimalViewer  view={view} analyse={analyse} zone={current_zone} years={current_year.map(c=>c.year)} datasets={datasets} />}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <ExcelFile filename="datas" element={<Button color="info">Télécharger</Button>}>
                            <ExcelSheet data={printdata} name="Cultures">
                                <ExcelColumn label="Cuture" value="culture" />
                                <ExcelColumn label="Année" value="year" />
                                <ExcelColumn label="Département" value="departement" />
                                <ExcelColumn label="Commune" value="commune" />
                                <ExcelColumn label="Superficie" value="superficie" />
                                <ExcelColumn label="Rendement" value="rendement" />
                                <ExcelColumn label="Production" value="production" />
                 
                            </ExcelSheet>
                        </ExcelFile>
                        {/* <Button color="info" onClick={toggleModal}>Télécharger</Button>{' '} */}
                    </ModalFooter>
                </Modal>

            </div>
            {/* <div className="mt-30">
                <ContentModuleStatisticTableGraphe tag="@vegetal" />
            </div> */}

            <div className="mt-30">
                <ContentModuleStatisticDocumentation tag="@doc_animal" />
            </div>

        </div>
    );
}

export default ContentModuleStatisticAnimal_;

