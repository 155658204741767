import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { getResource, postResource } from '../../../service/api';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ContentModuleStatisticHalieutiqueViewer from './ContentModuleStatisticHalieutiqueViewer';
import ContentModuleStatisticTableGraphe from './ContentModuleStatisticTableGraphe';
import ContentModuleStatisticDocumentation from './ContentModuleStatisticDocumentation';
import Select from 'react-select'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ContentModuleStatisticHalieutique_ = () => {
    const [currentThematique, setThematique] = useState(null)
    const [halieutiques, setHalieutiques] = useState([])
    const [zone, setZone] = useState(null)
    const [zones, setZones] = useState([])
    const [years, setYears] = useState([])
    const [year, setYear] = useState(null)
    const [loadingResult, setLoadingResult] = useState(false)

    const [current_halieutique, setCurrentHalieutique] = useState([])
    const [current_year, setCurrentYear] = useState([])
    const [current_zone, setCurrentZone] = useState(["BENIN"])

    const [datasets, setDataSets] = useState([])
    const [printdata, setPrintData] = useState([])


    const [modal, setModal] = useState(false)
    const [modalFilter, setModalFilter] = useState(false)
    const [currentIndicateur, setCurrentIndicateur] = useState(null)
    const [analyse, setAnalyse] = useState({ caption: "Analyse des données suivant Nombre des aquaculteurs", data: "effectif_cheptel", unit: "" })
    const [view, setView] = useState("line")
    const [commune, setCommune] = useState('BENIN')
    const [communes, setCommunes] = useState([])
    const [doRequest, setDoRequest] = useState(false)

    useEffect(() => {
        loadHalieutiques();

    }, [])

    useEffect(() => {
        loadYears()
    }, [current_halieutique])



    useEffect(() => {
        setZones(null)
        if (zone == 'communes') loadCommunes()
        if (zone == 'pda') loadPDA()
        if (zone == 'departements') loadDepartement([])

    }, [zone])

    useEffect(() => {
        // setZones(null)
        if (year == 'select_year') loadYears()
    }, [year])

    const toggleModal = (current = null) => {
        setModal(!modal)
        setCurrentIndicateur(current)
    }

    const toggleModalFilter = (thematique) => {
        setModalFilter(!modalFilter)
        if (thematique == "nbre_aquaculteur") setAnalyse({ caption: "Analyse des données suivant le Nombre d'aquaculteur", data: "nbre_aquaculteur", unit: "" })
        if (thematique == "nbre_producteur_alevin") setAnalyse({ caption: "Analyse des données suivant Nombre de producteurs d'alevins", data: "nbre_producteur_alevin", unit: "T" })
        if (thematique == "nbre_bassin") setAnalyse({ caption: "Analyse des données suivant le Nombre de bassin", data: "nbre_bassin", unit: "" })
        if (thematique == "nbre_trou_poisson") setAnalyse({ caption: "Analyse des données suivant le Nombre de trou à poisson", data: "nbre_trou_poisson", unit: "" })
        if (thematique == "nbre_bac_hors_sol") setAnalyse({ caption: "Analyse des données suivant le Nombre de bac hors sol ", data: "nbre_bac_hors_sol", unit: "" })
        if (thematique == "nbre_enclos") setAnalyse({ caption: "Analyse des données suivant le Nombre d'enclos", data: "nbre_enclos", unit: "" })
        if (thematique == "prod_alevin_clarias") setAnalyse({ caption: "Analyse des données suivant la Production d'arlevin de clarias", data: "prod_alevin_clarias", unit: "T" })
        if (thematique == "prod_alevin_tilapia") setAnalyse({ caption: "Analyse des données suivant la Production d'arlevin de tilapia", data: "prod_alevin_tilapia", unit: "T" })
        if (thematique == "prod_alevin_autre") setAnalyse({ caption: "Analyse des données suivant la Production autres arlevins", data: "prod_alevin_autre", unit: "T" })
        if (thematique == "prod_clarias") setAnalyse({ caption: "Analyse des données suivant la Production  de clarias", data: "prod_clarias", unit: "T" })
        if (thematique == "prod_tilapia") setAnalyse({ caption: "Analyse des données suivant la Production de tilapia", data: "prod_tilapia", unit: "T" })
        if (thematique == "prod_autre") setAnalyse({ caption: "Analyse des données suivant la Production autres poissons", data: "prod_autre", unit: "T" })
        if (thematique == "nbre_pecheur") setAnalyse({ caption: "Analyse des données suivant  le Nombre de pêcheurs", data: "nbre_pecheur", unit: "" })
        if (thematique == "nbre_pirogue") setAnalyse({ caption: "Analyse des données suivant  le Nombre de pirogues", data: "nbre_pirogue", unit: "" })
        if (thematique == "nbre_embarcad") setAnalyse({ caption: "Analyse des données suivant  le Nombre d'embarcadères/débarcadères", data: "nbre_embarcad", unit: "" })
        if (thematique == "capture") setAnalyse({ caption: "Analyse des données suivant  le Nombre de Capture", data: "capture", unit: "" })
        if (thematique == "nbre_debarquement") setAnalyse({ caption: "Analyse des données suivant  le Nombre de debarquement", data: "nbre_debarquement", unit: "" })
        if (thematique == "nbre_unite_peche") setAnalyse({ caption: "Analyse des données suivant  le Nombre de Nombre d'unité de pêche", data: "nbre_unite_peche", unit: "" })
        if (thematique == "nbre_bateau_licence") setAnalyse({ caption: "Analyse des données suivant  le Nombre de bateau licence", data: "nbre_bateau_licence", unit: "" })
        if (thematique == "capture_chalitiere") setAnalyse({ caption: "Analyse des données suivant  le Nombre de capture chalitiere", data: "capture_chalitiere", unit: "" })
        if (thematique == "capture_thoniere") setAnalyse({ caption: "Analyse des données suivant  le Nombre de capture chalitiere", data: "capture_thoniere", unit: "" })
        setThematique(thematique)
    }


    const loadHalieutiques = () => {
        getResource('halieutiques').then(res => {
            console.log(res);
            setHalieutiques(res.data.map(d => ({ value: d.halieutique, label: d.halieutique })))
        })
    }

    const loadCommunes = () => {
        getResource('communes_all').then(res => {
            setZones(res.data.map(d => ({ value: d.name, label: d.name })))
        })
    }

    const loadPDA = () => {
        getResource('pdas').then(res => {
            setZones(res.data.map(d => ({ value: d.pda, label: d.pda })))
        })
    }

    const loadDepartement = () => {
        getResource('departements_all').then(res => {
            setZones(res.data.map(d => ({ value: d.departement, label: d.departement })))
        })
    }

    const loadYears = () => {
        postResource('years_halieutique', { halieutiques: current_halieutique }).then(res => {
            setYears(res.data.map(d => ({ value: d.year, label: d.year })))
            setCurrentYear(res.data)
        })
    }

    // const findResult = () => {
    //     setLoadingResult(true)
    //     postResource('animalDatas', {
    //         halieutiques: current_halieutique,
    //         years: current_year.map(c=>c.year),
    //         communes: zones ? current_zone : ['BENIN']
    //     }).then(res => {

    //         setDataSets(res.data)
    //         toggleModal()
    //         setLoadingResult(false)
    //     }).finally(()=>{
    //         setLoadingResult(false)
    //     })

    // }


    const findResult = () => {
        setLoadingResult(true)

        let query = {
            halieutiques: current_halieutique,
            years: current_year.map(c => c.year),
            communes: zones ? current_zone : ['BENIN']
        }
        if (zone == "departements") {
            query = {
                halieutiques: current_halieutique,
                years: current_year.map(c => c.year),
                departement: current_zone[0]
            }
        }

        postResource('halieutiqueDatas', query).then(res => {
            if (res.data.length == 0) {
                alert('Aucun résultat disponible pour cette sélection, Veuillez effecuer un autre choix')
            } else {
                setDataSets(res.data)
                toggleModal()
                setLoadingResult(false)
                let data_print = Object.keys(datasets).map(cultures => datasets[cultures].map(c => c))[0]
                setPrintData(data_print ? data_print : [])
            }


        }).finally(() => {
            setLoadingResult(false)
        })

    }



    return (
        <div>
            <div className="">
                <div className="">
                    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#indicateursclés" role="tab"
                                aria-controls="indicateursclés" aria-selected="true">Indicateurs Clés
                            </a>
                        </li>

                        {/* <li>
                            <div className="input-group ml-5 w-75">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-transparent border-right-0" id="basic-addon1"><i className="fa fa-search"></i></span>
                                </div>
                                <input type="text" className="form-control border-left-0" placeholder="Filtre..." aria-label="Username"
                                    aria-describedby="basic-addon1" />
                            </div>
                        </li> */}
                    </ul>
                    <div className="tab-content p-3  " >
                        <div className="tab-pane fade show active pt-2" id="indicateursclés" role="tabpanel"
                            aria-labelledby="indicateursclés">
                            <div className="tab-content" id="nav-tabContent">
                                <h5 className="mt-2">Aquaculture</h5>
                                <div className="tab-pane fade show active" id="toutes-les-thématiques" role="tabpanel"
                                    aria-labelledby="toutes-les-thématiques">
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15" onClick={() => toggleModalFilter('nbre_aquaculteur')}>Nombre d'aquaculteur</button>
                                    <button className="btn btn-sm btn-warning pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_producteur_alevin')}>Nombre de producteurs d'alevin</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_bassin')}>Nbre de  bassins</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_trou_poisson')}>Nombre trou poisson</button>
                                    <button className="btn btn-sm btn-success pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_bac_hors_sol')}>Nombre bac hors sol</button>
                                    <button className="btn btn-sm btn-danger pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_enclos')}>Nbre d'enclos</button>
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('prod_alevin_clarias')}>Production d'alevin clarias (T)</button>
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('prod_clarias')}>Production  clarias (T)</button>
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('prod_alevin_tilapia')}>Production d'alevin tilapia (T)</button>
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('prod_tilapia')}>Production d'tilapia (T)</button>
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('prod_alevin_autre')}>Production d'alevin autres poissons (T)</button>
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('prod_autre')}>Production  autres poissons (T)</button>
                                </div>

                                <h5 className="mt-5">Pêche continentale</h5>
                                <div className="tab-pane fade show active" id="toutes-les-thématiques" role="tabpanel"
                                    aria-labelledby="toutes-les-thématiques">
                                    <button className="btn btn-sm btn-warning pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_pecheur')}>Nombre pêcheurs</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_pirogue')}>Nbre de  pirogues</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_embarcad')}>Nombre d'embarcadères/débarcadères</button>
                                    <button className="btn btn-sm btn-success pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('capture')}>Capture (T)</button>

                                </div>

                                <h5 className="mt-5">Pêche maritime artisanale</h5>
                                <div className="tab-pane fade show active" id="toutes-les-thématiques" role="tabpanel"
                                    aria-labelledby="toutes-les-thématiques">
                                    <button className="btn btn-sm btn-warning pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_pecheur')}>Nombre pêcheurs</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_debarquement')}>Nombre de site de débarquement</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_unite_peche')}>Nombre d'unité de pêche</button>
                                    <button className="btn btn-sm btn-success pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('capture')}>Capture (T)</button>

                                </div>


                                <h5 className="mt-5">Pêche maritime industrielle</h5>
                                <div className="tab-pane fade show active" id="toutes-les-thématiques" role="tabpanel"
                                    aria-labelledby="toutes-les-thématiques">
                                    <button className="btn btn-sm btn-warning pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('nbre_bateau_licence')}>Nombre de bâteaux sous license</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('capture_chalitiere')}>Capture chalitière(T)</button>
                                    <button className="btn btn-sm btn-primary pt-15 pb-15 pl-15 pr-15 mr-2 mt-15"
                                        onClick={() => toggleModalFilter('capture_thoniere')}>Capture pêche thonière (T)</button>


                                </div>

                            </div>

                        </div>

                    </div>
                </div>


                <div id="mySidenav" className={`sidenav section-title ${modalFilter && 'active'} text-white`}>
                    <i className="closebtn fa fa-close" onClick={() => toggleModalFilter()}></i>
                    <h5 className="text-white mb-4 text-uppercase"> {analyse.caption}</h5>
                    <div>
                        <h6 className="text-white mt-5 mb-3  criteria">Selectionner</h6>
                        <div >
                            {halieutiques && <div className="zone_area mb-3 notopradius nobottomleft nobottomright">
                                <Select isMulti options={halieutiques} onChange={(halieutique) => setCurrentHalieutique(halieutique.map(c => c.value))} />
                            </div>}
                        </div>
                        <h6 className="text-white mt-5  criteria mb-3  ">Zones géographiques</h6>
                        <div >
                            <div className="btn-group special " role="group" aria-label="Zones géographiques">
                                <button type="button" className={`btn ${!zone && 'btn-warning'}`} onClick={() => setZone(null)}>Bénin</button>
                                <button type="button" className={`btn ${zone == 'pda' && 'btn-warning'}`} onClick={() => setZone('pda')}>PDA</button>
                                <button type="button" className={`btn ${zone == 'departements' && 'btn-warning'}`} onClick={() => setZone('departements')}>Départements</button>
                                <button type="button" className={`btn ${zone == 'communes' && 'btn-warning'}`} onClick={() => setZone('communes')}>Communes</button>


                            </div>
                            {zones && <div className="zone_area mb-3 notopradius nobottomleft nobottomright">
                                <Select options={zones} onChange={(zone) => setCurrentZone([zone.value])} />
                            </div>}
                        </div>

                        <h6 className="text-white mt-5  criteria mb-3  ">Années</h6>
                        <div className="btn-group special " role="group" aria-label="Années">
                            <button type="button" className={`btn ${!year && 'btn-warning'}`} onClick={() => setYear(null)}>Toutes</button>
                            <button type="button" className={`btn ${year == 'select_year' && 'btn-warning'}`} onClick={() => setYear('select_year')}>Selectionner</button>
                        </div>
                        {year && <div className="zone_area mb-3 notopradius nobottomleft nobottomright  ">
                            <Select isMulti options={years} onChange={(year) => setCurrentYear(year.map(c => ({ year: c.value })))} />
                        </div>}

                    </div>
                    <Button disabled={current_halieutique.length == 0} className={`btn btn-lg ${current_halieutique.length > 0 && 'btn-info'} btn-block text-white mt-40`} onClick={() => { findResult() }}>Voir le résultat <i className={`fa ${loadingResult ? ' fa-spinner fa-pulse' : 'fa-search'} `}></i></Button>

                </div>

                <Modal isOpen={modal} toggle={toggleModal} size="lg" className="custom-modal-style" >
                    <ModalHeader toggle={toggleModal}>Visualisation de données   {currentIndicateur && currentIndicateur.animal}</ModalHeader>
                    <ModalBody>

                        <div className="card">
                            <div className="card-body bg-gray">
                                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'line' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("line")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Linéaire
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'bar' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("bar")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Histogramme
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className={`nav-link ${view == 'map' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("map")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Carte
                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'table' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("table")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Tableau
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content tab-content-white" >
                                    {datasets && <ContentModuleStatisticHalieutiqueViewer view={view} analyse={analyse} zone={current_zone} years={current_year.map(c => c.year)} datasets={datasets} />}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <ExcelFile filename="datas" element={<Button color="info">Télécharger</Button>}>
                            <ExcelSheet data={printdata} name="Cultures">
                                <ExcelColumn label="Cuture" value="culture" />
                                <ExcelColumn label="Année" value="year" />
                                <ExcelColumn label="Département" value="departement" />
                                <ExcelColumn label="Commune" value="commune" />
                                <ExcelColumn label="Superficie" value="superficie" />
                                <ExcelColumn label="Rendement" value="rendement" />
                                <ExcelColumn label="Production" value="production" />

                            </ExcelSheet>
                        </ExcelFile>
                        {/* <Button color="info" onClick={toggleModal}>Télécharger</Button>{' '} */}
                    </ModalFooter>
                </Modal>

            </div>
            {/* <div className="mt-30">
                <ContentModuleStatisticTableGraphe tag="@vegetal" />
            </div> */}

            <div className="mt-30">
                <ContentModuleStatisticDocumentation tag="@doc_halieutique" />
            </div>

        </div>
    );
}

export default ContentModuleStatisticHalieutique_;

