import React, { useEffect, useState } from 'react'
import { getResource, postResource } from '../../../service/api';
import { Bar, Line } from 'react-chartjs-2';
import { Table } from 'reactstrap';



export default function ContentModuleStatisticHalieutiqueViewer({   view, analyse, zone,years, datasets }) {

    const [dataSource, setDataSource] = useState(null)

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            return `${value} ${analyse.unit}`;
                        }
                    },
                },
            ],

        },
    }

    useEffect(() => {

        if (datasets) {

            const animals = Object.keys(datasets)
            const dataSource = {
                labels: years,
                datasets: animals.map(c => {
                    var randomColor = Math.floor(Math.random() * 16777215).toString(16)
                    return {
                        label: c,
                        data: datasets[c].map(d => d[analyse.data]),
                        fill: false,
                        backgroundColor: `#${randomColor}`,
                        borderColor: `#${randomColor}`,

                    }
                })
            }
            setDataSource(dataSource)

        }
    }, [])


    return (
        <>
        <h5 className="mt-20 mb-20 text-center">{analyse.caption} [{zone[0]}]</h5>
            {view == "line" && dataSource && <Line data={dataSource} options={options} />}
            {view == "bar" && dataSource && <Bar data={dataSource} options={options} />}
            {/* {view == "map" && <h5>En cours ...</h5>} */}
            {view == "table" && dataSource && <Table className="table table-bordered table-striped">
                <tr>
                <th>Années</th>
                <th>Zone</th>
                    <th>Thématique</th>
                    {/* <th>Effectif cheptel</th>
                    <th>Quantité de viande (T)</th>
                    <th>Nombre de tête abattu</th>   
                    <th>Poids carcasse abattage contrôlé (T)</th>   
                    <th>Qté de lait (T)</th>   
                    <th>Nombre de ruche</th>   
                    <th>Qté de miel (T)</th>    */}
                </tr>

                    {
                        Object.keys(datasets).map(animals=>datasets[animals].map(c=>(               
                             <tr>
                            <td className="text-bold">{c.year}</td>
                            <td>{zone[0]}</td>
                            <td>{c.halieutique}</td>
                            {/* <td>{c.effectif_cheptel}</td>
                            <td>{c.qte_viande}</td>
                            <td>{c.nbre_tete_abattu}</td>
                            <td>{c.poids_carcasse}</td>
                            <td>{c.qte_lait}</td>
                            <td>{c.nbre_ruche}</td>
                            <td>{c.qte_miel}</td> */}
                        </tr>
        )))
                    }
            </Table>}
        </>
    )
}
