import React, { useEffect, useState } from 'react';
import { getResource } from "../service/api";
import { useParams } from 'react-router-dom'

const ContentPage = () => {

    const [services, setServices] = useState([])
    const [page, setPage] = useState([])
    const { slug } = useParams();

    // ramifier ce code ultérieurement

    useEffect(() => {
        getResource(`pages?permalink=${slug}`).then(res => {
            if (res.data.data.length > 0) {
                setPage(res.data.data[0]);
            }

        })

        getResource(`pages?page_service=1&public=1`).then(res => {
            setServices(res.data.data)
        })

        console.log(slug);

    }, [])

    // useEffect content

    return (

        <section className="pt-15 pb-15">
            <div className="container content-page">
                <div className="row">
                    <div className="col-md-8">
                        <div className="privacy-desc privacy-list section-title text-align-justify">
                            <h5 className="mb-3">{page && page.title}</h5>
                            <div dangerouslySetInnerHTML={{ __html: page && page.content }}></div>
                        </div>
                    </div>

                    <div className="col-md-4 sidebar_service">

                        <ul className="list-group">
                            {
                                services && services.map(service => (
                                    <li key={service.id} className={`list-group-item defaultFont ${service.title == page.title ? 'colorGreen' : 'default'}`}><a href={`/service/${service.permalink}`}><small>{service.title}</small></a></li>
                                ))
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default ContentPage;