import React, { useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import Loader from '../components/Common/Loader'
import { getResource } from '../service/api';
import ContentService from '../components/Service/ContentService';
import "../assets/css/page.css"

export default function ServicePage() {
    const[services, setServices] = useState([]);

    useEffect(() => {

        getResource(`pages?page_service=1`).then(res => {
            if(res.data.data.length>0){
                setServices(res.data.data);
            }
            
        })
    }, []);

    return (
        <>
            {!services && <Loader />}
            {services && <ContentService services={services} />}
        </>
    )
}
