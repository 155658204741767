import React, { useContext, useEffect, useState } from "react";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import SlideToUp from "./components/Common/SlideToUp";

import {BrowserRouter as Router,Route,Switch} from "react-router-dom";

import NotFound from "./components/Common/NotFound";
import Page from "./pages/Page";
import Iframe from "./pages/Iframe";
import Home from './pages/Home';
import Module from "./pages/Module";
import { AppContext } from "./App";
import News from "./pages/News";
import Welcome from "./pages/Welcome";
import Statistique from "./pages/Statistique";
import Phototheque from "./pages/Phototheque";
import SearchView from "./pages/SearchView";

import ServicePage from "./pages/Services";
import ServiceDsa from "./pages/ServicesDsa";

import Mediatheque from "./pages/Mediatheque";
import MediathequeItems from "./pages/MediathequeItems";


const AppRouter = (props) => {
  const {appState, setAppState} = useContext(AppContext)

  return (
    <Router>
      {appState.defaultdatas&&<Header />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/page/:menu_parent/:slug" component={Page} />
        <Route path="/page/:slug" component={Page} />
        <Route path="/frame/:slug" component={Iframe} />
        <Route path="/news/:slug" component={News} />
        <Route path="/section/:menu_parent/:slug" component={Module} />
        <Route path="/welcome" component={Welcome} />

        <Route path="/content/:menu_parent/services" component={ServicePage} />
        <Route path="/service/:slug" component={ServiceDsa} />

        <Route path="/statistics/:slug" component={Statistique} />
        <Route path="/phototheque/:slug" component={Phototheque} />
        <Route path="/recherche/:slug" component={SearchView} />
        <Route path="/mediatheque" component={Mediatheque} />
        <Route path="/mediatheque-items/:slug" component={MediathequeItems} />

        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <SlideToUp/>
    </Router>

  )
}



export default AppRouter;
