import React, { useContext, useEffect, useState } from 'react'
import Ariane from '../components/Common/Ariane';
import Loader from '../components/Common/Loader'
import { AppContext } from '../App';
import {getResource} from '../service/api';

const directory = process.env.REACT_APP_UPLOAD_DIRECTORY
export default function Welcome() {

    const { appState: { defaultdatas } } = useContext(AppContext)
    const [setting, setSetting] = useState([])

    useEffect(() => {
        // if (defaultdatas) {
        //     // setSetting(defaultdatas.setting)
        //     // setSetting(defaultdatas.setting.data.length > 0 ? defaultdatas.setting.data[0] : null)
        // }
       
            getResource(`settings`).then(res => {
                setSetting(res.data.data.length > 0 ? res.data.data[0] : null)
            })
    }, [])

    return (
        <>
            {!setting && <Loader />}
            {setting && <Ariane title={setting.welcome_title} />}
            {setting && <section className="pt-50 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="privacy-desc">
                                <h2 className="mb-30">{setting.welcome_title}</h2>
                            </div>
                            <div className="about-cont">
                            { setting && setting.fichier &&<img  src={`${directory}/${setting.fichier.filename}`}
                                    className=" about-img-page img-fluid img-thumbnail rounded  mr-auto d-block" alt="" />}
                                <div dangerouslySetInnerHTML={{ __html: setting && setting.welcome_content }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>
    )
}
