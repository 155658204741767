import React, { useContext, useEffect } from 'react';

import BannerSlider from '../components/Home/BannerSlider';
import SecondSlider from '../components/Home/SecondSlider';
import DirectorWords from '../components/Home/DirectorWords';
import Newsletter from "../components/Footer/Newsletter";
import CountNumber from "../components/Home/CountNumber";
import Actualites from "../components/Home/Actualites";

export default function Home() {
    
    return (
        <>
            <BannerSlider />
            <DirectorWords />
            <CountNumber />
            <Actualites />
            <Newsletter />
        </>
    )
}
