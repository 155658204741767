import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import {Redirect, Link} from 'react-router-dom';

export default function SearchBar() {

    const {appState, setAppState} = useContext(AppContext);
    const [query, setQuery] = useState([]);
    

    const onSubmit = (e) => {

        e.preventDefault();

        return  window.location.assign(`/recherche/${query}`);

    }

    const getQuery = (e) => {

        setQuery(e.target.value);
        console.log(query)

    }

    return (
            <div className="search-box" style={{display:appState.searchBarDisplay?"block":"none"}}>
                <div className="search-form">
                    <div className="closebtn" onClick={()=>{setAppState({...appState, searchBarDisplay:false})}} >
                        <span></span>
                        <span></span>
                    </div>
                    <form onSubmit={onSubmit}>
                        <input type="text" onChange={getQuery} name="query" placeholder="Recherchez un mot clé" />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </form>
                </div>
            </div>
    )
}
