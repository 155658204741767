import React, { useEffect, useState } from 'react';
import Links from "../components/Footer/Links";
import { getResource } from "../service/api";

const PUBLIC_URL = process.env.PUBLIC_URL

export default function Footer() {
    const [setting, setSetting] = useState([])
    const [gaUsers, setGaUsers] = useState(null)

    useEffect(() => {
        getResource(`settings`).then(res => {
            setSetting(res.data.data[0])
        })
    }, [])

    useEffect(() => {
        window.gapi && window.gapi.client
            .init({
                discoveryDocs: [
                    "https://content.googleapis.com/discovery/v1/apis/analyticsreporting/v4/rest",
                ],
            })
            .then(() => {
                window.gapi.client.analyticsreporting.reports.batchGet({
                    "resource": {
                        "reportRequests": [
                            {
                                "viewId": '260668725',
                                "dateRanges": [
                                    {
                                      "startDate": "2021-06-01",
                                      "endDate": "today"
                                    }
                                  ]
                            }
                        ]
                    }
                })
                    .then(response => {
                        console.log(response.result)
                        setGaUsers(response.result.reports[0].data.totals[0].values);
                        console.log(gaUsers)
                        //JSON.stringify(gaUsers);
                    },
                        function (err) { console.error("Execute error", err); });
            });
    }, [])

    var ladate = new Date()
    ladate = ladate.getFullYear()
    return (
        <>
            <footer className="site-footer">
                <div className="container">
                    <div className="row pb-5 pt-4">
                        <div className="col-md-3 pt-2 mt-2">
                            <img src={`${PUBLIC_URL}/assets/images/Logo DSA.png`} alt="Drapeau du Bénin" className="logoF d-inline pb-3 footer-logo" />
                        </div>
                        <div className="col-md-7">

                            <Links />

                            {gaUsers && gaUsers > 0 && <div class=" justify-content-center one" >
                              <button class="btn btn-outline-secondary mb-4 waves-effect waves-light visitcountbtn text-center">
                                    <span class="visitCount">{gaUsers}</span>
                                    <span class="visitcount"> <i class="fa fa-users" ></i></span>
                                </button>
                            </div>}
                        </div>

                        <div className="col-md-2 pt-2 mt-2">
                            <img src={`${PUBLIC_URL}/assets/images/europeanunion.jpg`} alt="Union Européenne" className="d-inline logoF pb-3 footer-logo pull-right" />
                        </div>

                        {gaUsers && gaUsers > 0 && <div class="mx-auto two" style={{width:"120px"}}>
                              <button class="btn btn-outline-secondary mx-auto text-center mb-4 waves-effect waves-light visitcountbtn text-center">
                                    <span class="visitCount">{gaUsers}</span>
                                    <span class="visitcount"> <i class="fa fa-users" ></i></span>
                                </button>
                            </div>}

                        <div className='mx-auto'>
                            <p className="ministere text-center text-white">
                                © {setting && setting.copyright_author} - {ladate}
                            </p>

                        </div>
                        <small class="text-white col-lg-12 mt-3 ml-1 mr-1 mb-0 text-center">{setting && setting.copyright}</small>
                    </div>
                </div>
                <div className="flag-look-like">
                    <div className="col green-block"></div>
                    <div className="col yellow-block"></div>
                    <div className="col red-block"></div>
                </div>
            </footer>
        </>
    )
}
