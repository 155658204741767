import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { getResource } from '../../service/api';

const ContentIframe = ({url}) => {
    const iframe = `<iframe height="265" style="width: 100%;" scrolling="no" src="${url}" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>`; 
  
    return ( 
        <section className="pt-15 pb-15">
        <div className="container page">
            <div className="row">
                <div className={"col-md-12"}>
                    <div className="privacy-desc privacy-list section-title text-align-justify">
                    {/* <h5 className="mb-3">{page && page.title}</h5> */}
                        <div dangerouslySetInnerHTML={ {__html:iframe} }></div>
                    </div>
                </div>

            </div>
    
        </div>
    </section>
     );
}
 
export default ContentIframe;