import React from 'react';
import Marquee from "react-fast-marquee"

const MarqueeNews = ({datas}) => {
    return (
        <div className="d-flex alert-zone" id="alertinfo_top" >
            <div className="p-1 pe-2 bd-highlight bg-dark text-white info-flash">
                <span className="hide4mobile" onload="">Alerte info </span>
            </div>

            <Marquee  pauseOnHover speed={50} gradient={false}>
                {datas.map(marquee=>(
                <a key={marquee.id} href={`/news/${marquee.permalink}`} className="nodecorator">{marquee.title}</a>            
                ))}
            </Marquee>
        </div>
    );
}

export default MarqueeNews;