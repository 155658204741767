import React, { useEffect, useState } from 'react';
import { getResource } from '../../../service/api';
import ContentLoader from 'react-content-loader'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const MyLoader = () => (
    <ContentLoader viewBox="0 0 380 150">
        <rect x="0" y="20" rx="4" ry="4" width="100%" height="18" />
        <rect x="0" y="45" rx="3" ry="3" width="100%" height="18" />
        <rect x="0" y="70" rx="3" ry="3" width="100%" height="18" />
        <rect x="0" y="95" rx="3" ry="3" width="100%" height="18" />
    </ContentLoader>
)
const ContentModuleStatisticTableGraphe = ({tag}) => {
    const [module, setModule] = useState(null)
    const [current_module, setCurrentModule] = useState(null)
    const [current_indicateur, setCurrentIndicateur] = useState(null)
    const [content, setContent] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [numberPage, setNumberPage] = useState([])

    useEffect(() => {
        getResource(`modules?tag=${tag}`).then(res => {
            setModule(res.data.data.length > 0 ? res.data.data[0].children : null)
            if(res.data.data[0])
            setCurrentModule(res.data.data[0].children[0])

        })
    }, [])

    useEffect(() => {
        setLoading(true)

        if (current_module)
            getResource(`pages?module_id=${current_module.id}&page=${currentPage}&public=1`).then(res => {
                setContent(res.data.data)
                let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
                array_pages = Array.from(Array(array_pages).keys())
                setNumberPage(array_pages);
                setLoading(false)
            }).catch(err => {
                setLoading(false)
            })
    }, [current_module,currentPage])

    return (
        <div id="simple-page" class=" bg-gray">
            <div class="container">
                <div class="row">
                    <ul class="nav nav-tabs nav-fill w-100" id="performanceTab" role="tablist">
                        {module && module.map(m => (
                            <li key={m.id} class="nav-item" >
                                <a class={`nav-link cursor ${current_module && current_module.tag == m.tag && 'active'}`} id="tableaux-tab" data-toggle="tab" onClick={() => setCurrentModule(m)} role="tab"
                                    aria-controls={m.id} aria-selected="true">{m.name}</a>
                            </li>
                        ))}

                    </ul>
                    
                    <div class="tab-content w-100" id="performanceTabContent">
                        <div class="tab-pane fade show active" id="tableaux" role="tabpanel" aria-labelledby="tableaux-tab">

                            <div class="col-sm-12 col-md-12 p-3 pb-2">
                                {current_module && current_module.tag == "@table" && <h4>Liste des tableaux</h4>}
                                {current_module && current_module.tag == "@graphe" && <h4>Liste des graphes</h4>}
                                {current_module && current_module.tag == "@figure" && <h4>Liste des figures</h4>}
                                <div class="list-group p-3">
                                    {!loading && content.map(c => (
                                        <button key={c.id} type="button" onClick={() => setCurrentIndicateur(c)} class={`list-group-item list-group-item-action cursor p-3`} aria-current="true">
                                            {c.title}</button>
                                    ))}
                                    {loading && <MyLoader />}
                                </div>

                            </div>
                        </div>
                    </div>

                    <Modal isOpen={current_indicateur} toggle={() => setCurrentIndicateur(null)} size="lg" fullscreen>
                        <ModalHeader toggle={() => setCurrentIndicateur(null)}> {current_indicateur && current_indicateur.title}</ModalHeader>
                        <ModalBody>
                            <div dangerouslySetInnerHTML={{ __html: current_indicateur && current_indicateur.content }}></div>
                        </ModalBody>
                    </Modal>

                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
        </div>

    );
}

export default ContentModuleStatisticTableGraphe;