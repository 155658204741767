import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const directory_doc = process.env.REACT_APP_UPLOAD_DIRECTORY;

export default function ContentModulePartners({ module }) {

    const [partners, setPartners] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPartner, setCurrentPartner] = useState(null)
    const [numberPage, setNumberPage] = useState([])

    useEffect(() => {
        getResource(`partners?modules_partners_id=${module.id}&page=${currentPage}&public=1`).then(res => {
            setPartners(res.data.data)
            let array_pages = Math.ceil(parseInt(res.data.total) / parseInt(res.data.per_page))
            array_pages = Array.from(Array(array_pages).keys())
            setNumberPage(array_pages);
        })
    }, [currentPage])

    const toggle = ()=>{
        setCurrentPartner(null)
    }


    return (
        <div>
            <section id="event-page" className="pt-10 pb-20 ">
                <div className="container">
                    <div className="row">
                        {
                            partners.map(p => (
                                <div className="col-lg-3 cursor" key={p.id} onClick={()=>setCurrentPartner(p)}>
                                    <div className="single-event-list-2 mt-30">

                                        {p.fichier && <div className="event-thum">
                                            <img src={`${directory_doc}/${p.fichier.filename}`} alt={p.name} />
                                        </div>}
                                        <div className="event-cont">
                                            <a  className="text-center cursor">
                                                <h4>{p.name}</h4>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }



                    </div>
                    <div className="row">
                    <div className="col-lg-12">
                        <nav className="courses-pagination mt-50 pb-3">
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)} aria-label="Précédent" >
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                                {
                                    numberPage.map((n) => (
                                        <li key={`page${n + 1}`} className="page-item"><a className={currentPage == (n + 1) && 'active'} onClick={() => setCurrentPage(n + 1)}>{n + 1}</a></li>
                                    ))
                                }
                                <li className="page-item">
                                    <a onClick={() => currentPage < numberPage.length && setCurrentPage(currentPage + 1)} aria-label="Suivant">
                                        <i className="fa fa-angle-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                </div>
            </section>

            <Modal isOpen={currentPartner} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>{currentPartner && currentPartner.name}</ModalHeader>
                <ModalBody>
                    <img src={`${directory_doc}/${currentPartner && currentPartner.fichier.filename}`} alt={currentPartner && currentPartner.name} className="partner-img img-fluid rounded  d-block m-3" />
                    <div >
                         {currentPartner && currentPartner.presentation}
                        
                    </div>
                </ModalBody>
                <ModalFooter>
                <Button color="info" onClick={toggle}>Site internet</Button>{' '}                
                </ModalFooter>
            </Modal>

        </div>
    )
}
