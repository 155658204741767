import React, { useState, useEffect } from 'react';
import { getResource, postResource } from '../../../service/api';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ContentModuleStatisticVegetalViewer from './ContentModuleStatisticVegetalViewer';
import ContentModuleStatisticTableGraphe from './ContentModuleStatisticTableGraphe';
import ContentModuleStatisticDocumentation from './ContentModuleStatisticDocumentation';
import Select from 'react-select'

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ContentModuleStatisticVegetal = () => {
    const [currentThematique, setThematique] = useState(null)
    const [cultures, setCultures] = useState([])
    const [zone, setZone] = useState('all')
    const [zones, setZones] = useState([])
    const [years, setYears] = useState([])
    const [year, setYear] = useState(null)
    const [loadingResult, setLoadingResult] = useState(false)

    const [current_culture, setCurrentCulture] = useState([])
    const [current_year, setCurrentYear] = useState([])
    const [current_zone, setCurrentZone] = useState(["BENIN"])

    const [datasets, setDataSets] = useState([])
    const [printdata, setPrintData] = useState(null)


    const [modal, setModal] = useState(false)
    const [modalFilter, setModalFilter] = useState(false)
    const [currentIndicateur, setCurrentIndicateur] = useState(null)
    const [analyse, setAnalyse] = useState({ caption: "Analyse des données suivant la Production", data: "production", unit: "T" })
    const [view, setView] = useState("line")

    useEffect(() => {
        loadCulture();
    }, [])


    useEffect(() => {
        loadYears()
    }, [current_culture])


    useEffect(() => {
             setZones(null)

        if (zone == 'all') setCurrentZone(["BENIN"])
        if (zone == 'communes') loadCommunes()
        if (zone == 'pda') loadPDA()
        if (zone == 'departements') loadDepartement()

    }, [zone])

    useEffect(() => {
       // setZones(null)
        if (year == 'select_year') loadYears()
    }, [year])

 
    const toggleModal = (current = null) => {
        setModal(!modal)
        setCurrentIndicateur(current)
    }

    const toggleModalFilter = (thematique) => {
        setModalFilter(!modalFilter)
        if (thematique == "production") setAnalyse({ caption: "Analyse des données suivant la Production", data: "production", unit: "T" })
        if (thematique == "rendement") setAnalyse({ caption: "Analyse des données suivant la Rendement", data: "rendement", unit: "Kg/Ha" })
        if (thematique == "superficie") setAnalyse({ caption: "Analyse des données suivant la Superficie", data: "superficie", unit: "Ha" })
        setThematique(thematique)
    }


    const loadCulture = () => {
        getResource('cultures').then(res => {
            setCultures(res.data.map(d => ({ value: d.culture, label: d.culture })))
        })
    }

    const loadCommunes = () => {
        getResource('communes_all').then(res => {
            setZones(res.data.map(d => ({ value: d.name, label: d.name })))
        })
    }

    const loadPDA = () => {
        getResource('pdas').then(res => {
            setZones(res.data.map(d => ({ value: d.pda, label: d.pda })))
        })
    }

    const loadDepartement = () => {
        getResource('departements_all').then(res => {
            setZones(res.data.map(d => ({ value: d.departement, label: d.departement })))
        })
    }

    const loadYears = () => {
        postResource('years', { cultures: current_culture }).then(res => {
            setYears(res.data.map(d => ({ value: d.year, label: d.year })))
            setCurrentYear(res.data)
        })
    }

    useEffect(()=>{
        let data_print = Object.keys(datasets).map(cultures => datasets[cultures].map(c => c))[0]
                setPrintData(data_print ? data_print : [])
    },[datasets])
    

    const findResult = () => {
        setLoadingResult(true)

        let query = {
            cultures: current_culture,
            years: current_year.map(c => c.year),
            communes: zones ? current_zone : ['BENIN']
        }
        if (zone == "departements") {
            query = {
                cultures: current_culture,
                years: current_year.map(c => c.year),
                departement: current_zone[0]
            }
        }

        postResource('cultureDatas', query).then(res => {
            if(res.data.length==0){
                alert('Aucun résultat disponible pour cette sélection, Veuillez effecuer un autre choix')
            }else {
                setDataSets(res.data)
                toggleModal()
                setLoadingResult(false)
        
            }


        }).finally(() => {
            setLoadingResult(false)
        })

    }


    return (
        <div>
            <div className="">
                <div className="">
                    <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#indicateursclés" role="tab"
                                aria-controls="indicateursclés" aria-selected="true">Indicateurs Clés
            </a>
                        </li>

                        {/* <li>
                            <div className="input-group ml-5 w-75">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-transparent border-right-0" id="basic-addon1"><i className="fa fa-search"></i></span>
                                </div>
                                <input type="text" className="form-control border-left-0" placeholder="Filtre..." aria-label="Username"
                                    aria-describedby="basic-addon1" />
                            </div>
                        </li> */}
                    </ul>
                    <div className="tab-content p-3 bgstatistics  " >
                        <div className="tab-pane  fade show active pt-2" id="indicateursclés" role="tabpanel"
                            aria-labelledby="indicateursclés">
                            <div className="tab-content" id="nav-tabContent">

                                <div className="tab-pane fade show active" id="toutes-les-thématiques" role="tabpanel"
                                    aria-labelledby="toutes-les-thématiques">
                                    <button className="btn btn-sm btn-info pt-15 pb-15 pl-15 pr-15 mr-2 mb-3 btn-block" onClick={() => toggleModalFilter('superficie')}>Superficie</button>
                                    <button className="btn btn-sm btn-warning pt-15 pb-15 pl-15 pr-15 mr-2 mb-3 btn-block"
                                        onClick={() => toggleModalFilter('rendement')}>Rendement</button>
                                    <button className="btn btn-sm btn-success pt-15 pb-15 pl-15 pr-15 mr-2 mb-3 btn-block"
                                        onClick={() => toggleModalFilter('production')}>Production</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div id="mySidenav" className={`sidenav section-title ${modalFilter && 'active'} text-white`}>
                    <i className="closebtn fa fa-close" onClick={() => toggleModalFilter()}></i>
                    <h5 className="text-white mb-4 text-uppercase">INDICATEUR {currentThematique}</h5>
                    <div>
                        <h6 className="text-white mt-5 mb-3  criteria">Selectionner cultures</h6>
                        <div >
                            {cultures && <div className="zone_area mb-3 notopradius nobottomleft nobottomright">
                                <Select isMulti options={cultures} onChange={(culture) => setCurrentCulture(culture.map(c => c.value))} />
                            </div>}
                        </div>
                        <h6 className="text-white mt-5  criteria mb-3  ">Zones géographiques</h6>
                        <div >
                            <div className="btn-group special " role="group" aria-label="Zones géographiques">
                                <button type="button" className={`btn ${zone == 'all' && 'btn-warning'}`} onClick={() => setZone('all')}>Bénin</button>
                                <button type="button" className={`btn ${zone == 'pda' && 'btn-warning'}`} onClick={() => setZone('pda')}>PDA</button>
                                <button type="button" className={`btn ${zone == 'departements' && 'btn-warning'}`} onClick={() => setZone('departements')}>Départements</button>
                                <button type="button" className={`btn ${zone == 'communes' && 'btn-warning'}`} onClick={() => setZone('communes')}>Communes</button>


                            </div>
                            {zones && <div className="zone_area mb-3 notopradius nobottomleft nobottomright">
                                <Select options={zones} onChange={(zone) => setCurrentZone([zone.value])} />
                            </div>}
                        </div>

                        <h6 className="text-white mt-5  criteria mb-3  ">Années</h6>
                        <div className="btn-group special " role="group" aria-label="Années">
                            <button type="button" className={`btn ${!year && 'btn-warning'}`} onClick={() => setYear(null)}>Toutes</button>
                            <button type="button" className={`btn ${year == 'select_year' && 'btn-warning'}`} onClick={() => setYear('select_year')}>Selectionner</button>
                        </div>
                        {year && <div className="zone_area mb-3 notopradius nobottomleft nobottomright  ">
                            <Select isMulti options={years} onChange={(year) => setCurrentYear(year.map(c => ({ year: c.value })))} />
                        </div>}

                    </div>

                    <Button disabled={current_culture.length ==0 } className={`btn btn-lg ${current_culture.length > 0  &&'btn-info'} btn-block text-white mt-40`} onClick={() => { findResult() }}>Voir le résultat <i className={`fa ${loadingResult ? ' fa-spinner fa-pulse' : 'fa-search'} `}></i></Button>
                </div>

                <Modal isOpen={modal} toggle={toggleModal} size="lg" className="custom-modal-style" >
                    <ModalHeader toggle={toggleModal}>Visualisation de données   {currentIndicateur && currentIndicateur.culture}</ModalHeader>
                    <ModalBody>

                        <div className="card">
                            <div className="card-body bg-gray">
                                <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'line' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("line")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Linéaire
                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'bar' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("bar")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Histogramme
                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a className={`nav-link ${view == 'map' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("map")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Carte
                        </a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a className={`nav-link ${view == 'table' && 'active'}`} id="home-tab" data-toggle="tab" onClick={() => setView("table")} role="tab"
                                            aria-controls="indicateursclés" aria-selected="true"> Tableau
                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content tab-content-white" >
                                    {datasets && <ContentModuleStatisticVegetalViewer view={view} analyse={analyse} zone={current_zone} years={current_year.map(c => c.year)} datasets={datasets} />}
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                      {printdata &&  <ExcelFile filename="datas" element={<Button color="info">Télécharger</Button>}>
                            <ExcelSheet data={printdata} name="Cultures">
                                <ExcelColumn label="Cuture" value="culture" />
                                <ExcelColumn label="Année" value="year" />
                                <ExcelColumn label="Département" value="departement" />
                                <ExcelColumn label="Commune" value="commune" />
                                <ExcelColumn label="Superficie" value="superficie" />
                                <ExcelColumn label="Rendement" value="rendement" />
                                <ExcelColumn label="Production" value="production" />
                 
                            </ExcelSheet>
                        </ExcelFile> }

                    </ModalFooter>
                </Modal>

            </div>
            {/* <div className="mt-30">
                <ContentModuleStatisticTableGraphe tag="@vegetal" />
            </div> */}

            <div className="mt-30">
                <ContentModuleStatisticDocumentation tag="@doc_vegetal" />
            </div>

        </div>
    );
}

export default ContentModuleStatisticVegetal;

