import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import ContentModuleStatisticVegetal from './statistics/ContentModuleStatisticVegetal';
import ContentModuleStatisticHalieutique from './statistics/ContentModuleStatisticHalieutique';
import ContentModuleStatisticAnimal from './statistics/ContentModuleStatisticAnimal';
import ContentModuleStatisticDocumentation from './statistics/ContentModuleStatisticDocumentation';
import ContentModuleStatisticAnimal_ from './statistics/ContentModuleStatisticAnimal_';
import ContentModuleStatisticHalieutique_ from './statistics/ContentModuleStatisticHalieutique_';

export default function ContentModuleStatistic() {
    const { slug } = useParams();
    return (
        <div id="simple-page statictic" className="pt-90 pb-120 bg-white">


            <div className="container">
                <div className="row">

                    <div className="col-sm-12 col-md-4">
                        {/* <h4 className="mb-3">Les indicateurs</h4> */}
                        <div className="list-group" id="list-tab" role="tablist">
                            {THEMATIQUES.map(t => (
                                <a key={t.name} className={`list-group-item list-group-item-action ${slug === t.path && 'active'} `} data-toggle="list"
                                    href={`/statistics/${t.enabled?t.path:''}`} role="tab" aria-controls="toutes-les-thématiques">{t.name} <i
                                        className="fa fa-arrow-right float-right"></i></a>
                            ))}

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                    {THEMATIQUES.map(t => {
                        return slug==t.path && t.component
                    })}
               

                    </div>
                </div>

            </div>

    </div>


    )
}


const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }
    ]
};

const THEMATIQUES = [
    { name: "Production Végétale", path: "vegetale", enabled:true, component:<ContentModuleStatisticVegetal /> },
    { name: "Production Halieutique", path: "halieutique", enabled:true,tag:"@halieutique",component:<ContentModuleStatisticHalieutique_ /> },
    { name: "Production Animale", path: "animale", enabled:true, component:<ContentModuleStatisticAnimal_ tag="@animal"/> },
    { name: "Pluviométrie et autres données agro-météorologiques", path: "meteorologique", enabled:true, component:<ContentModuleStatisticDocumentation tag="@meteorologique"/> },
    { name: "Données complémentaires", path: "complementaire", enabled:true , component:<ContentModuleStatisticDocumentation tag="@complementaire"/>},
    { name: "Indicateurs macro économique", path: "macroeconomique", enabled:true,tag:"@macroeconomique" , component:<ContentModuleStatisticDocumentation tag="@macroeconomique"/> },
    { name: "Enquetes", path: "enquetes", enabled:true, tag:"@enquete" , component:<ContentModuleStatisticDocumentation tag="@enquete"/>},
    { name: "Etudes", path: "etudes", enabled:true, tag:"@etude" , component:<ContentModuleStatisticDocumentation tag="@etude"/>},
    { name: "Annuaires statistiques", path: "annuairestatistiques", enabled:true, tag:"@annuairestatistique" , component:<ContentModuleStatisticDocumentation tag="@annuairestatistique"/>},
    { name: "Compte économique du secteur agricole", path: "compteeconomique", enabled:true,tag:"@compteeconomique" , component:<ContentModuleStatisticDocumentation tag="@compteeconomique"/> },
    { name: "Prix des produits agricoles", path: "prixagricole", enabled:true,tag:"@prixagricole" , component:<ContentModuleStatisticDocumentation tag="@prixagricole"/> },
    { name: "Indicateurs du PSDSA et de Malabo", path: "indicateur_psdsa", enabled:true,tag:"@indicateur_psdsa" , component:<ContentModuleStatisticDocumentation tag="@indicateur_psdsa"/> },
]