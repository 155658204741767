import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../App';
import { getResource } from '../../service/api';

const ContentPage = ({page}) => {
    const { appState: { defaultdatas } } = useContext(AppContext)
    const[services, setServices] = useState([]);
    useEffect(()=>{
            if(page.page_service){
                loadService()
            }
    },[])
    const loadService=()=>{
        getResource(`pages?page_service=1`).then(res => {
            if(res.data.data.length>0){
                setServices(res.data.data);
            }
            
        })
    }
    return ( 
        <section className="pt-15 pb-15">
        <div className="container page">
            <div className="row">
                <div className={page.page_service?"col-md-8":"col-md-12"}>
                    <div className="privacy-desc privacy-list section-title text-align-justify">
                    <h5 className="mb-3">{page && page.title}</h5>
                        <div dangerouslySetInnerHTML={ {__html:page.content} }></div>
                    </div>
                </div>

                {page.page_service ? <div className="col-md-4 sidebar_service">
                        <ul className="list-group">
                            {
                                services.map(p => (
                                    <li key={p.id} className={page.id==p.id?"list-group-item active":"list-group-item"}><a href={`/page/${p.permalink}`}><small>{p.title}</small></a></li>
                                ))
                            }

                        </ul>
                    </div>: ""}
            </div>
    
        </div>
    </section>
     );
}
 
export default ContentPage;