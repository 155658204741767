import React, { useEffect, useState } from 'react'
import { getResource, postResource } from '../../../service/api';
import { Bar, Line } from 'react-chartjs-2';
import { Table } from 'reactstrap';
import { CSVLink, CSVDownload } from "react-csv";
import _ from 'lodash'


export default function ContentModuleStatisticVegetalViewer({   view, analyse, zone,years, datasets }) {

    const [dataSource, setDataSource] = useState(null)

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: function (value, index, values) {
                            return `${value} ${analyse.unit}`;
                        }
                    },
                },
            ],

        },
    }

    useEffect(() => {
        if (datasets) {
            
            let cultures = Object.keys(datasets)
            cultures = _.uniqWith(cultures, _.isEqual)
            const dataSource = {
                labels: years,
                datasets: cultures.map(c => {
                    var randomColor = Math.floor(Math.random() * 16777215).toString(16)
                    return {
                        label: c,
                        data: datasets[c].map(d => d[analyse.data]),
                        fill: false,
                        backgroundColor: `#${randomColor}`,
                        borderColor: `#${randomColor}`,

                    }
                })
            }
            setDataSource(dataSource)

        }
    }, [])


    return (
        <>
        <h5 className="mt-20 mb-20 text-center">{analyse.caption} [{zone[0]}]</h5>
            {view == "line" && <Line data={dataSource} options={options} />}
            {view == "bar" && <Bar data={dataSource} options={options} />}
            {/* {view == "map" && <h5>En cours ...</h5>} */}
            {view == "table" && <Table className="table table-bordered table-striped">
                <tr>
                <th>Années</th>
                    <th>Culture</th>
                    <th>Superficie (Ha)</th>
                    <th>Rendement (Kg/Ha)</th>
                    <th>Production (Tonne)</th>
                </tr>

                    {
                        Object.keys(datasets).map(cultures=>datasets[cultures].map(c=>(               
                             <tr>
                            <td className="text-bold">{c.year}</td>
                            <td>{c.culture}</td>
                            <td>{c.superficie}</td>
                            <td>{parseInt(c.rendement)}</td>
                            <td>{parseInt(c.production)}</td>
                        </tr>
        )))
                    }
            </Table>}
        </>
    )
}
