import React, { useEffect, useState } from 'react'
import { getResource } from '../../service/api'


export default function ContentModulePhotos() {

    const [modulePhotos, setModulePhotos] = useState([])
    useEffect(()=>{
        getResource(`modules?type_module=medias&public=1`).then(res=>{
            setModulePhotos(res.data.data);
        })
    },[])

    return (
        <section id="teachers-page" class="pt-90 pb-120 gray-bg">
        <div class="container">
           <div class="row">
               {
                   modulePhotos.map(module=>(
                    <div class="col-lg-4 col-sm-12" key={module.id}>
                    <div class="single-teachers mt-30 text-center">
                         <div class="image">
                             <img src="/assets/images/teachers/t-1.jpg" alt="Teachers"/>
                         </div>
                         <div class="cont">
                             <span>{module.name}</span>
                         </div>
                     </div>
                </div>
                   ))
               }
           </div>
            <div class="row">
                <div class="col-lg-12">
                    <nav class="courses-pagination mt-50">
                        <ul class="pagination justify-content-center">
                            <li class="page-item">
                                <a href="#" aria-label="Previous">
                                    <i class="fa fa-angle-left"></i>
                                </a>
                            </li>
                            <li class="page-item"><a class="active" href="#">1</a></li>
                            <li class="page-item"><a href="#">2</a></li>
                            <li class="page-item"><a href="#">3</a></li>
                            <li class="page-item">
                                <a href="#" aria-label="Next">
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>  
                </div>
            </div> 
        </div> 
    </section>



    )
}
