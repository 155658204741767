import React, { useContext } from 'react';
import { AppContext } from '../../App';
import moment from 'moment'
import { Alert } from 'react-bootstrap';

export default function Publication() {
    const { appState: { defaultdatas } } = useContext(AppContext)
    return (
        <div className=" communiques">
            <div className="about-event ">
                <div className="event-title">
                    <h3>Communiqués</h3>
                </div>
                <ul>

                    {defaultdatas && typeof defaultdatas.communiques !== 'undefined' && defaultdatas.communiques.data.filter(publication => publication.public == 1).length > 0 ? defaultdatas.communiques.data.map(publication => (
                        <li key={publication.id}>
                            <div className="single-event">
                                <a href={`/news/${publication.permalink}`}>
                                    <div className="row">
                                        <div className="col-md-3 text-left">
                                            <h3><i className="fa fa-calendar"></i> {moment(publication.created_at).format('DD')}</h3>
                                            {moment(publication.created_at).format('MM')} | <b>{moment(publication.created_at).format('YYYY')}</b>
                                        </div>
                                        <div className="col-md-9"><h4>{publication.title}</h4></div>
                                    </div>
                                </a>
                            </div>
                        </li>
                    )) : <Alert className="col-md-12 mx-auto" variant="secondary"><h4 className="text-success">Aucun communiqué disponible</h4></Alert>}
                    {defaultdatas && typeof defaultdatas.communiques !== 'undefined' && defaultdatas.communiques.length > 4 ? 
                        <li>
                            <div className="single-event">
                                <a href="/section/home/Actualités" className="w-100 main-btn mt-20 text-white">
                                    Voir Plus
                                </a>
                            </div>
                        </li>
                     : ""}
                </ul>
            </div>
        </div>
    )
}
