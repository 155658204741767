import React, { useContext } from 'react';
import { AppContext } from '../../App';
import Slider from "react-slick";
import '../../assets/css/weblink.css';
import '../../assets/css/style-acces-rapide.css';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div className="col-md-1 arrowPositionLeft survol" >

        <span
            {...props}
            className={
                "slick-prev slick-arrow" +
                (currentSlide === 0 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-left fa-2x" aria-hidden="true"></i>
        </span>
    </div>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (

    <div className="col-md-1 arrowPositionRight survol">
        <span
            {...props}
            className={
                "slick-next slick-arrow" +
                (currentSlide === slideCount - 1 ? " slick-disabled" : "")
            }
            style={{ display: "block" }}

            aria-hidden="true"
            aria-disabled={currentSlide === slideCount - 1 ? true : false}
            type="button"
        >
            <i class="fa fa-angle-double-right fa-2x" aria-hidden="true"></i>

        </span>
    </div>
)

const Links = () => {
    const { appState: { defaultdatas } } = useContext(AppContext)

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <SlickArrowRight />,
        prevArrow: <SlickArrowLeft />,
        autoplaySpeed: 2000,
        focusOnSelect: true,
        draggable: true,
        accessibility: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (

        <div className="pb-2 mb-3 weblink">
            <h5 className="text-center text-white">LIENS UTILES</h5>

            <Slider {...settings} style={{ height: '100px' }}>
                {defaultdatas && defaultdatas.weblinks.map(link => (
                    <div key={link.id} className="rounded border border-light text-white p-2">
                        <a className="text-white liens" href={link.access_link}>
                            <span className="lien-icon"><i className="fa fa-link"></i></span>
                            {link.name}
                        </a>
                    </div>
                ))}
            </Slider>

            <div class='links'>
                <p>
                    <div>
                        <h5 className="text-center text-white title-acces">Accès Rapide</h5>

                    </div>
                    <div className="row acces-rapide">
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dsa.agriculture.gouv.bj/content/DSA/services" className="text-white size-text">Organisation</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/page/DSA/mission-et-attributions" className="text-white size-text">Mission et attributions</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/page/DSA/lequipe" className="text-white size-text">Personnel</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/DSA/Partenaires" className="text-white size-text">Nos Partenaires</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/home/Actualités" className="text-white size-text">Actualités</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/statistics/vegetale" className="text-white size-text">Statistiques</a></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Recensement/Méthodologies, concepts et nomenclatures" className="text-white size-text">Méthodologies, Concepts et Nomenclatures</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Recensement/Documents méthodologiques" className="text-white size-text">Documents méthodologiques</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Recensement/Documents administratifs" className="text-white size-text">Documents administratifs</a></li>

                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Recensement/Résultats (principaux tableaux)" className="text-white size-text">Résultats (principaux tableaux)</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Recensement/Autres Publications" className="text-white size-text">Autres Publications</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Documentations/Annuaires statistiques" className="text-white size-text">Annuaires Statistiques</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Documentations/Réglémentations statistiques" className="text-white size-text">Réglémentations Statistiques</a></li>

                            </ul>
                        </div>
                        <div className="col-md-3">
                            <ul>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Documentations/AOF" className="text-white size-text">Décrets, AOF, Méthodologies</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Documentations/Rapports" className="text-white size-text">Rapports d'ateliers</a></li>
                                <li><a href="https://web.facebook.com/dsabenin2015" className="text-white size-text">Nous Suivre</a></li>
                                <li><a href="http://dsa.agriculture.gouv.bj/section/Contacts/Contacts" className="text-white size-text">Contact</a></li>
                                <li><a href="https://dsa.agriculture.gouv.bj/page/mentions-legales" className="text-white size-text">Mentions Légales</a></li>
                            </ul>
                        </div>
                    </div>
                </p>
            </div>

        </div>
    )
}

export default Links
