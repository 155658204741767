import React, { useContext, useEffect, useState } from 'react';
import Publication from './Publication';
import { AppContext } from '../../App';
import { getResource } from '../../service/api';

const directory = process.env.REACT_APP_UPLOAD_DIRECTORY
export default function DirectorWords() {

    const { appState: { defaultdatas } } = useContext(AppContext)
    const [setting, setSetting] = useState(null)

    useEffect(() => {
        if (defaultdatas) {
            setSetting( defaultdatas.setting)
        }
    }, [defaultdatas])


    return (

        <section id="about-part" className="animated ">
            { setting && <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="section-title mt-50">
                            <h5>{setting.welcome_title}</h5>
                            <div className="row">
                            </div>
                        </div>
                        <div className="about-cont">
                        { setting && setting.fichier &&<img src={`${directory}/${setting.fichier.filename}`} 
                                className=" about-img img-fluid img-thumbnail rounded  mr-auto d-block" alt="Directuer MAEP" />}
                            <div className="justify" dangerouslySetInnerHTML={{ __html: setting && setting.welcome_content.slice(0,500) }}></div>

                            <a href="/welcome" className="main-btn mt-55">Lire plus</a>
                        </div>
                    </div>
                    <div className="col-lg-5">
                    <Publication />
                    </div>

                </div>
            </div>
            }
        </section>

    )
}
